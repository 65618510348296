import React from "react";

const APP_GOOGLE_CLIENT_ID =
  "22223583074-1l7325ng3j5s569clojrs72e749k8ros.apps.googleusercontent.com";

const url =
  "https://accounts.google.com/o/oauth2/v2/auth?" +
  `client_id=${APP_GOOGLE_CLIENT_ID}` +
  "&" +
  `redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}` +
  "&access_type=offline" +
  "&prompt=consent" +
  "&response_type=code&scope=https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/drive.metadata.readonly";

function App() {
  return (
    <div>
      <h1>Importador datos para SGO</h1>
      <a href={url}>Conceder permisos de lectura para Google Drive</a>
    </div>
  );
}
export default App;
